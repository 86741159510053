
import { defineComponent, onMounted, ref, watch } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

import { useChannels, useArchiveChannel, useRecoverChannel } from '@/composables/api';
import {
  ChannelIdOptions,
  ResponseError
} from '@/services/api';
import permissionUnits, { canIDo } from '@/components/permission-units/index.vue';
import ChannelAdminsDialog from './components/ChannelAdminsDialog.vue';
import CreateChannelAdminDialog from './components/CreateChannelAdminDialog.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import { Channel, ChannelType } from '@/interfaces/Channel';
import ChannelCommissions from './components/ChannelCommissions.vue';
import { useRoute } from 'vue-router';

const FILTER_OPTIONS: FilterOption[] = [
  {
    label: 'Type',
    type: FilterType.SELECTOR,
    placeholder: 'Please select an option',
    options: Object
      .entries(ChannelType)
      .map(([label, value]) => ({ label, value }))
  },
  {
    label: 'Is Disabled',
    type: FilterType.CHECKBOX
  }
];

export default defineComponent({
  components: {
    permissionUnits,
    ChannelAdminsDialog,
    CreateChannelAdminDialog,
    Filter,
    ChannelCommissions
  },
  setup() {
    const page = ref(1);
    const type = ref();
    const isArchived = ref();
    const isBanned = ref();
    const sortBy = ref();
    const descSortBy = ref('updated_at');

    const route = useRoute();
    const { data, isLoading, isFetching, refetch } = useChannels({ page, type, sortBy, descSortBy, isBanned, isArchived });

    const { isLoading: isArchivedLoading, mutate: archive } = useArchiveChannel();
    const { isLoading: isRecoveringLoading, mutate: recover } = useRecoverChannel();
    const sortChange = ({ prop, order }) => {
      if (!prop) {
        descSortBy.value = '';
        sortBy.value = '';
        return;
      }

      if (order === 'ascending') {
        sortBy.value = prop;
        descSortBy.value = '';
      } else {
        descSortBy.value = prop;
        sortBy.value = '';
      }
    };

    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      type.value = event[0];
      isBanned.value = event[1] ? '1' : '';
    };

    const isAdminsDialogVisible = ref(false);
    const isCreateAdminDialogVisible = ref(false);
    const isProfitSharingDialogVisible = ref(false);

    const commissionsData = ref();
    const currentChannelId = ref<ChannelIdOptions['channelId']>();

    const toggleArchivedData = () => {
      if (!isArchived.value) {
        isArchived.value = true;
      } else {
        isArchived.value = null;
      }
    };

    watch(
      [isAdminsDialogVisible, isCreateAdminDialogVisible],
      ([isAdminsDialogVisible, isCreateAdminDialogVisible]) => {
        const isDialogClosed = !isAdminsDialogVisible || !isCreateAdminDialogVisible;
        if (isDialogClosed) refetch.value();
      });

    const openAdminsDialog = async({ channelId }: ChannelIdOptions) => {
      currentChannelId.value = channelId;
      isAdminsDialogVisible.value = true;
    };

    const openCreateAdminDialog = async({ channelId }: ChannelIdOptions) => {
      currentChannelId.value = channelId;
      isCreateAdminDialogVisible.value = true;
    };

    const openProfitSharingDialog = (row: Channel) => {
      isProfitSharingDialogVisible.value = true;
      commissionsData.value = row.channelCommissions;
    };

    onMounted(() => {
      const routeQuery = route.query;

      if (routeQuery.page && (routeQuery.isEdited !== '1')) {
        page.value = Number(route.query.page);
      }
    });

    const archiveChannel = ({ channelId }: ChannelIdOptions) => {
      currentChannelId.value = channelId;

      ElMessageBox.confirm('Are you sure you want to archive?', 'Warning', {
        confirmButtonText: 'Archive',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          archive(
            { channelId },
            {
              onSuccess() {
                refetch.value();

                ElMessage({
                  type: 'success',
                  message: 'Archive successfully'
                });
              },
              onError(error: ResponseError) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    const recoverChannel = ({ channelId }: ChannelIdOptions) => {
      currentChannelId.value = channelId;

      ElMessageBox.confirm('Are you sure you want to recover?', 'Warning', {
        confirmButtonText: 'Recover',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          recover(
            { channelId },
            {
              onSuccess() {
                refetch.value();

                ElMessage({
                  type: 'success',
                  message: 'Recover successfully'
                });
              },
              onError(error: ResponseError) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    return {
      page,
      data,
      isLoading,
      isFetching,
      FILTER_OPTIONS,
      commissionsData,
      isArchivedLoading,
      currentChannelId,
      isAdminsDialogVisible,
      isRecoveringLoading,
      isCreateAdminDialogVisible,
      sortChange,
      isArchived,
      recoverChannel,
      archiveChannel,
      openAdminsDialog,
      handleFilterChange,
      openCreateAdminDialog,
      openProfitSharingDialog,
      toggleArchivedData,
      isProfitSharingDialogVisible,
      canIListAdmins: canIDo('list-channel-admins')
    };
  }
});
